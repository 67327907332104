.header {
  width: 100%;
  height: 80px;
  box-shadow: 0 0 5px 1px #c0bfbf;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 100;
  transition: all .5s;
}

.header__link {
  gap: 1.5em;
}

.mobile-menu {
  display: none;
}

@media screen and (max-width: 860px) {
  .header {
    justify-content: space-between;
    padding: 0 30px;
  }
  .mobile-menu {
    display: block;
    color: var(--orange);
    width: 35px;
  }

  .mobile-menu:hover {
    cursor: pointer;
  }

  .mobile-menu .menu-icon,
  .mobile-menu .close-icon {
    width: 35px;
    height: 35px;
  }

  .header__link {
    width: 100%;
    position: absolute;
    left: 0;
    top: 80px;
    background: var(--orange);
    padding: 2em;
    flex-direction: column;
    align-items: start;
    transform: translateX(-110%);
    animation: slide-in 0.4s ease 1 forwards alternate;
  }

  .header__link a {
    color: var(--white);
  }

  .header__link a:hover {
    color: var(--white);
    transition: scale(1.01);
  }

  .header__logo {
    width: 180px;
  }

  .hidden {
    display: none;
  }
}

@keyframes menu-slide-in {
  0% {
    transform: translateX(90%);
    opacity: 0.2;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
