@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

html{
  font-size: 16px;
}

body {
  min-height: 100vh;
  margin-top: 80px;
  /* position: relative; */
}

body::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: var(--white);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--blue);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}
