.answer {
    width: 100%;
    gap: 3em;
    padding: 2em;
}


.answer__allContainer {
    width: 75vw;
    gap: 3em;
}
.answer__container {
    width: 100%;
}

.answer__question h3{
    color: var(--darkGray);
    margin: 0 .5em;
}
.answer__question p {
    margin: .4em .5em;
}

.answer_title h2{
    color: var(--darkGray);
    margin: 1em 0;
    padding: .5em 0;
    border-top : 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    
}

.answer__form a{
    color: var(--purple);
    margin-bottom: .5em;
}

.answer__form a:hover{
    text-decoration: underline;
}

.answer__description ul li{
    margin-bottom: .5em;
}

.answer__form {
    width: 60vw;
    padding: 2em 4em;
    box-shadow:5px 5px 5px 1px #e6e4e4 ;
    border-radius: 1em;
    text-align: center;
    border: 2px solid #eee;
    overflow: auto;
}

.answer__form form {
    padding: 1em 0;
}

.answer__form form textarea {
    width: 100%;
}


@media screen and (max-width: 600px) { 
    .answer_title h2,
    .answer__question h3{
        font-size: 16px;
    }
    .answer__question p,
    .answer__form .textarea ,
    .answer__form a{
        font-size: 14px;
    }
    .answer__question h1,
    .answer__form h1 {
        font-size: 20px;
    }
    .answer__form {
        width: 90vw;
        padding: 2em 2em;
    }
    .answer__form .btn {
        font-size: 12px;
        font-weight: normal;
    }
    
    
}