.signup {
  width: 100%;
  height: 100vh;
  background: url("https://www.evangadi.com/themes/humans/assets/images/misc/bg-svg-f.svg") no-repeat;
  background-color: var(--lightGray);
  background-size: cover;
  box-sizing: border-box;
  gap: 2em;
}

.signup__wrapper {
  width: 580px;
  height: 550px;
  padding: 3em;
  box-shadow: 0 0 5px 1px #ebebeb;
  border-radius: 0.5em;
  background: var(--white);
  overflow: hidden;
  position: relative;
}

.signup__top {
  text-align: center;
  margin-bottom: 2em;
}

.signup__top h3 {
  color: var(--purple);
  margin-bottom: 1rem;
  font-size: 1.3em;
}

.signup__top p,
.signup_container form p {
  color: #666;
  text-align: center;
}

.signup__top p a,
.signup_container form p a {
  text-decoration: none;
  color: var(--orange);
  margin-left: 0.4em;
  text-align: center;
}

.signup__link--bottom {
  text-decoration: none;
  color: var(--orange);
  margin-left: 0.4em;
  text-align: center;
}

.signup__top p a:hover {
  text-decoration: underline;
}

.signup_container form .forgot__link {
  text-decoration: none;
  color: var(--orange);
  text-align: right;
  margin-bottom: 5px;
}

.signup_container input {
  width: 100%;
  color: var(--darkGray);
  font-size: 16px;
  height: 50px;
  padding: 12px;
  outline: none;
  border: 0 none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: .5em;
}

.signup_container input:focus {
  border-bottom: 2px solid var(--orange);
}

.signup_container form button {
  width: 100%;
  background: var(--blue);
  font-size: 1.2em;
  font-weight: normal;
}
.signup_container form button:hover {
  cursor: pointer;
  background: var(--orange);
}

.signup__name {
  display: flex;
  gap: 0.5em;
  margin-bottom: -25px;
}

@media screen and (max-width: 700px) {
  .signup {
    height: 1200px;
    justify-content: start;
    padding-top: 4em;
    flex-direction: column;
  }
  .signup__wrapper {
    width: 90vw;
    height: 640px;
    padding: 40px;
  }
  .signup__wrapper  .btn {
    padding: 0.5em 10px !important;
    font-size: 1em;
  }
  .terms__link {
    font-size: 11px;
  }
  .signup__top p,
  .bottom-link a,
  .forgot__link a {
    font-size: 14px;
  }
  .signup__top h3 {
    font-size: 20px;
  }
}

@media (min-width: 700px) and (max-width: 1060px) {

  .signup__wrapper {
    width: 400px;
    height: 640px;
    padding: 40px;
  }
  .signup__wrapper  .btn {
    padding: 0.5em 10px !important;
    font-size: 1em;
  }

}