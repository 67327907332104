.display {
    width: 100%;
    padding: 1em 1em;
    background-color: var(--lightGray);
    margin-bottom: 1em;
    border-radius: 1em;
    box-shadow: 0 0 2px 1px #b3b3b3;
}

.display__container {
    width: 70vw;
}

.display:hover {
    cursor: pointer;
    box-shadow: 0 0 2px 1px var(--orange);
}

.avatar {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.avatar img {
    width: 100%;
    height: 100px;
}

.right__arrow {
    background: transparent;
    color: rgb(31, 30, 30);
}

.right__arrow:hover {
    color: var(--orange);
}

.question h3,  .avatar h4 {
    font-weight: normal;
}

@media screen  and (max-width: 600px){
    .avatar {
        width: 150px;
    }
    .avatar img {
        width: 60px;
        height: 60px;
    }
    .avatar h4 {
       font-size: 10px;
       font-weight: normal;
    }
    .question {
        font-size: 11px;
    }
    .question h3{
        font-size: 12px;
        font-weight: normal;
    }
    .question p{
        font-size: 11px;
    }
  }


  @media screen  and (max-width: 860px){
    .avatar {
        width: 200px;
    }
    .column {
        flex-direction: column;
    }
}