.login {
  width: 100%;
  height: 100vh;
  background: url("https://www.evangadi.com/themes/humans/assets/images/misc/bg-svg-f.svg")
    no-repeat;
  background-color: var(--lightGray);
  background-size: cover;
  box-sizing: border-box;
  gap: 2em;
}

.login__wrapper {
  width: 580px;
  height: 550px;
  padding: 3em;
  box-shadow: 0 0 5px 1px #ebebeb;
  border-radius: 0.5em;
  background: var(--white);
  overflow: hidden;
  position: relative;
}

.login__top {
  text-align: center;
  margin-bottom: 2em;
}

.login__top h3 {
  margin-bottom: 1rem;
  font-size: 1.3em;
}

.login__top p {
  color: var(--darkGray);
}

.login_container .login__link {
  margin-left: 0.4em;
}

.login_container .login__link:hover {
  text-decoration: underline;
}

.login_container form .forgot__link {
  width: 100%;
  text-decoration: none;
  color: var(--orange);
  text-align: right;
  margin: 1em 0;
}

.login_container .input {
  border-radius: 4px;
}

.login_container input:focus {
  border-bottom: 2px solid var(--orange);
}

.login_container form button {
  width: 100%;
  background: #516cf0;
  font-size: 1.2em;
  font-weight: normal;
}
.login_container form button:hover {
  cursor: pointer;
  background: var(--orange);
}

.login_container .login__bottom--link {
  text-align: center;
  margin-top: 1em;
}


@media screen and (max-width: 700px) {
  .login {
    height: 1200px;
    justify-content: start;
    padding-top: 4em;
    flex-direction: column;
  }
  .login__wrapper {
    width: 90vw;
    height: 500px;
    padding: 40px;
  }
  .login__top p,
  .bottom-link a,
  .forgot__link a {
    font-size: 14px;
  }
  .login__top h3 {
    font-size: 20px;
  }
}

@media (min-width: 700px) and (max-width: 1060px) {
  .login__wrapper {
    width: 400px;
    height: 500px;
    padding: 40px;
  }
}
